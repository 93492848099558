import user from './user/actions';
import menu from './menu/actions';
import settings from './settings/actions';
import config from './config/actions';
import userDB from './userDB/actions';

export default {
  ...user,
  ...menu,
  ...settings,
  ...config,
  ...userDB,
};
