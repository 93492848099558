import React, { lazy, Suspense } from 'react';
import WhatsAppWidget from 'react-whatsapp-widget';
import { Route, Redirect, Switch } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import { connect } from 'react-redux';
import Layout from 'layouts';

import 'react-whatsapp-widget/dist/index.css';

const routes = [
  {
    key: 'home',
    path: '/dashboard',
    Component: lazy(() => import('views/dashboard')),
    exact: true,
    analista: true,
  },
  {
    key: 'config',
    path: '/configuration',
    Component: lazy(() => import('views/configuration')),
    exact: true,
    analista: true,
  },
  {
    key: 'report',
    path: '/reports',
    Component: lazy(() => import('genericComponents/Construction/index')),
    exact: true,
    analista: true,
  },
  {
    key: 'billing',
    path: '/billing/:type',
    Component: lazy(() => import('views/billing')),
    exact: true,
    analista: true,
  },
  {
    path: '/contact',
    Component: lazy(() => import('views/contact')),
    exact: true,
  },
  {
    path: '/questions',
    Component: lazy(() => import('views/contact/Questions')),
    exact: true,
  },
  {
    path: '/privacy',
    Component: lazy(() => import('views/contact/Privacy')),
    exact: true,
  },
  {
    path: '/admin',
    Component: lazy(() => import('views/administrador')),
    exact: true,
    roles: ['administrador'],
  },
  {
    path: '/admin/invoice',
    Component: lazy(() => import('views/administrador')),
    exact: true,
    roles: ['administrador'],
  },
  {
    path: '/admin/issuer',
    Component: lazy(() => import('views/administrador/Facturacion/Issuer')),
    exact: true,
    roles: ['administrador'],
  },
  {
    path: '/promotional-codes/:type',
    Component: lazy(() => import('views/administrador/Codigos/Tabs')),
    exact: true,
    roles: ['administrador'],
  },
  {
    path: '/promotional-codes/codes/:id',
    Component: lazy(() => import('views/administrador/Codigos/Tabs/Upsert')),
    exact: true,
    roles: ['administrador'],
  },
  {
    path: '/membresias/:type',
    Component: lazy(() => import('views/administrador/Membresias/Tabs')),
    exact: true,
    roles: ['administrador'],
  },
  {
    path: '/membresias/communicates/:id',
    Component: lazy(() => import('views/administrador/Membresias/Tabs/Upsert')),
    exact: true,
    roles: ['administrador'],
  },
  {
    path: '/admin/prices',
    Component: lazy(() => import('views/administrador/Precios/Upsert/index')),
    exact: true,
    roles: ['administrador'],
  },
  {
    path: '/users-admin',
    Component: lazy(() => import('views/users-admin')),
    exact: true,
    roles: ['administrador'],
  },
  {
    path: '/users-admin/:id',
    Component: lazy(() => import('views/users-admin/Upsert/index')),
    exact: true,
    roles: ['administrador'],
  },
  {
    path: '/me',
    Component: lazy(() => import('views/profile')),
    exact: true,
  },
  {
    path: '/me/analista',
    Component: lazy(() => import('views/profile/AddAnalista/index')),
    exact: true,
    roles: ['administrador', 'usuario'],
  },
  {
    path: '/auth/login',
    Component: lazy(() => import('pages/auth/login')),
    exact: true,
  },
  {
    path: '/auth/404',
    Component: lazy(() => import('pages/auth/404')),
    exact: true,
  },
];

const mapStateToProps = ({ settings, user }) => ({
  user,
  routerAnimation: settings.routerAnimation,
});

const Router = ({ history, routerAnimation, user }) => (
  <ConnectedRouter history={history}>
    <Layout>
      <Route
        render={(state) => {
          const { location } = state;
          return (
            <SwitchTransition>
              <CSSTransition
                key={location.pathname}
                appear
                classNames={routerAnimation}
                timeout={routerAnimation === 'none' ? 0 : 300}
              >
                <Switch location={location}>
                  <Route
                    exact
                    path="/"
                    render={() => (
                      <Redirect
                        to={
                          user?.type === 'analista'
                            ? '/contact'
                            : '/dashboard'
                        }
                      />
                    )}
                  />
                  {routes.map(({
                    key, path, Component, exact, roles, analista,
                  }) => {
                    if (
                      analista
                        && user?.type === 'analista'
                        && (
                          !user?.permissions
                            || (
                              Array.isArray(user?.permissions)
                                && !user?.permissions?.includes(key)
                            )
                        )
                    ) return null;
                    if (!roles || (Array.isArray(roles) && roles.includes(user?.type))) {
                      return (
                        <Route
                          path={path}
                          key={path}
                          exact={exact}
                          render={() => (
                            <div className={routerAnimation}>
                              <Suspense fallback={null}>
                                <Component />
                              </Suspense>
                            </div>
                          )}
                        />
                      );
                    }
                    return null;
                  })}
                  <Redirect to="/auth/404" />
                </Switch>
              </CSSTransition>
            </SwitchTransition>
          );
        }}
      />
    </Layout>
    <WhatsAppWidget
      sendButton="Enviar"
      placeholder="Escribe un mensaje"
      textReplyTime="Normalmente responde en 10 minutos"
      message="Hola, ¿cómo podemos ayudarte hoy?"
      companyName="Alertas GasGas"
      phoneNumber="+5218115353962"
    />
  </ConnectedRouter>
);

export default connect(mapStateToProps)(Router);
