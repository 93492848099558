import { notification } from 'antd';
import { getToken, URL_SERVER } from '../../helpers';

export class CRUD {
  constructor(url = '', hasToken = true) {
    this.url = url;
    this.hasToken = hasToken;
    this.controller = undefined;
    this.signal = undefined;
  }

  getDomain(url = '') {
    return `${URL_SERVER}/${this.url}/${url}`;
  }

  SetCancelToken() {
    const controller = new AbortController();
    const { signal } = controller;
    this.controller = controller;
    this.signal = signal;
  }

  Cancel() {
    if (this?.controller && this?.controller?.abort) this.controller.abort();
  }

  CleanCancelToken() {
    this.controller = undefined;
    this.signal = undefined;
  }

  async sendRequest({
    url, info, method, buffer, showError = true,
  }) {
    try {
      let token = {};
      if (this.hasToken) {
        const tkn = getToken();
        token = {
          Authorization: `Bearer ${tkn}`,
        };
      }
      const signal = this.signal ? { signal: this.signal } : {};
      const response = await fetch(this.getDomain(url), {
        ...signal,
        method,
        body:
          info && typeof info === 'object' ? JSON.stringify(info) : undefined,
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          ...token,
        },
      });
      if (!response.ok) {
        if (response?.status === 401) {
          window.location.reload();
        }
        const { status, error } = await response.json();
        throw new Error(`${status} - ${error.message} ${error.error}`);
      }
      if (buffer) {
        return response.blob();
      }
      const { ok, error, ...rest } = await response.json();
      if (!ok) {
        if (error.code === 401) {
          window.location.reload();
        }
        throw new Error(`${error.code} - ${error.message}`);
      }
      return rest;
    } catch ({ name, message }) {
      if (name === 'AbortError') {
        this.CleanCancelToken();
        console.error('fetch', message);
        return { error: 'AbortError' };
      }
      if (showError) notification.error({ message });
      else throw new Error(message);
      return false;
    }
  }

  async Get(url, showError) {
    return this.sendRequest({ url, showError, method: 'get' });
  }

  async Post(info, showError) {
    return this.sendRequest({ info, showError, method: 'post' });
  }

  async PostUrl(url, info, showError) {
    return this.sendRequest({
      url,
      info,
      showError,
      method: 'post',
    });
  }

  async PostBuffer(url, info, showError) {
    return this.sendRequest({
      url,
      info,
      showError,
      buffer: true,
      method: 'post',
    });
  }

  async Put(info, showError) {
    return this.sendRequest({ info, showError, method: 'put' });
  }

  async PutUrl(url, info, showError) {
    return this.sendRequest({
      url,
      info,
      showError,
      method: 'put',
    });
  }

  async Delete(url, showError) {
    return this.sendRequest({ url, showError, method: 'delete' });
  }
}

export default CRUD;
