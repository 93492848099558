import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import user from './user/reducers';
import menu from './menu/reducers';
import settings from './settings/reducers';
import config from './config/reducers';
import userDB from './userDB/reducers';

export default history => combineReducers({
  router: connectRouter(history),
  user,
  menu,
  settings,
  config,
  userDB,
});
