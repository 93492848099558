import { all, put, takeEvery } from 'redux-saga/effects';
import actions from './actions';
import Crud from '../../services/fetch';
import { getQuerys } from '../../helpers';

const users = new Crud('user');

export function* GET_ALL_ANALIST({ payload }) {
  yield put({ type: actions.LOADING_USERS, payload: true });
  const query = getQuerys(payload);
  const info = yield users.Get(`all/analista?${query}`);
  if (info) {
    yield put({
      type: actions.GET_ALL_USER,
      payload: info,
      loading: false,
    });
  } else {
    yield put({ type: actions.LOADING_USERS, payload: false });
  }
}

export function* GET_ALL_USER({ payload }) {
  yield put({ type: actions.LOADING_USERS, payload: true });
  const query = getQuerys(payload);
  const info = yield users.Get(`all/usuario?${query}`);
  if (info) {
    yield put({
      type: actions.GET_ALL_USER,
      payload: info,
      loading: false,
    });
  } else {
    yield put({ type: actions.LOADING_USERS, payload: false });
  }
}

export function* GET_ALL_ADMIN({ payload }) {
  yield put({ type: actions.LOADING_USERS, payload: true });
  const query = getQuerys(payload);
  const info = yield users.Get(`all/administrador?${query}`);
  if (info) {
    yield put({
      type: actions.GET_ALL_USER,
      payload: info,
      loading: false,
    });
  } else {
    yield put({ type: actions.LOADING_USERS, payload: false });
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.FETCH_ALL_ANALIST, GET_ALL_ANALIST),
    takeEvery(actions.FETCH_ALL_USER, GET_ALL_USER),
    takeEvery(actions.FETCH_ALL_ADMIN, GET_ALL_ADMIN),
  ]);
}
