import { all } from 'redux-saga/effects';

export function* SET_TITLE() {
  yield (window.title = 'GasGas Analytics');
}

export default function* rootSaga() {
  yield all([
    SET_TITLE(), // run once on app load to fetch menu data
  ]);
}
