import { notification } from 'antd';

export const DOMAIN = 'alertasgasgas.com';

export const APP_NAME = 'GasGas Analytics';

export const URL_SERVER = !/localhost/g.test(window.location.host) ? `https://backend.${DOMAIN}` : 'http://localhost:5000';

export const tokenNameStorage = 'app.gasgas.token';

export function getToken() {
  return window.localStorage.getItem(tokenNameStorage);
}

export function getQuerys(json = {}) {
  const query = new URLSearchParams();
  Object.keys(json).forEach((key) => {
    query.append(key, json[key]);
  });
  return query;
}

export function SendOk(message = '¡Correcto!', description) {
  notification.success({ message, description });
}

export function SendError(message = '¡Error!', description) {
  notification.error({ message, description });
}

export function SendWarning(message = '¡Cuidado!', description) {
  notification.warn({ message, description });
}
