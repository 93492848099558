const actions = {
  DISABLED_USER: 'user/DISABLED',
  CLOSED_USER: 'user/CLOSED',
  LOADING_USERS: 'user/LOADING_USERS',
  LOADING_USER: 'user/LOADING_USER',
  CLEAN_ALL_USER: 'user/CLEAN_ALL',
  CLEAN_USERS: 'user/CLEAN_USERS',
  CLEAN_USER: 'user/CLEAN_USER',
  GET_ALL_ANALIST: 'user/GET_ALL_ANALIST',
  GET_ALL_USER: 'user/GET_ALL_USER',
  GET_ALL_ADMIN: 'user/GET_ALL_ADMIN',
  FETCH_ALL_ANALIST: 'user/FETCH_ALL_ANALIST',
  FETCH_ALL_USER: 'user/FETCH_ALL_USER',
  FETCH_ALL_ADMIN: 'user/FETCH_ALL_ADMIN',
  GET_USER: 'user/GET',
  FETCH_USER: 'user/FETCH',
  CREATE_USER: 'user/CREATE',
  UPDATE_USER: 'user/UPDATE',
  DELETE_USER: 'user/DELETE',
  LOGIN_LANDIGPAGE: 'user/LOGIN_LANDIGPAGE',
};

export default actions;
