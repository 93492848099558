import React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import UserMenu from './UserMenu';

import style from './style.module.scss';

const TopBar = ({ intl }) => (
  <div className={style.topbar}>
    <div className="mr-auto" />
    <div className="">
      <UserMenu intl={intl} FormattedMessage={FormattedMessage} />
    </div>
  </div>
);

export default injectIntl(TopBar);
