import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import {
  Menu, Layout, Row, Button,
} from 'antd';
import classNames from 'classnames';
import store from 'store';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { find } from 'lodash';
import style from './style.module.scss';

const mapStateToProps = ({ menu, settings, user }) => ({
  menuData: menu.menuData,
  isMenuCollapsed: settings.isMenuCollapsed,
  isMobileView: settings.isMobileView,
  isMenuUnfixed: settings.isMenuUnfixed,
  isMenuShadow: settings.isMenuShadow,
  leftMenuWidth: settings.leftMenuWidth,
  menuColor: settings.menuColor,
  logo: settings.logo,
  role: user.type,
  permissions: user.permissions,
});

const MenuLeft = ({
  dispatch,
  menuData = [],
  location: { pathname },

  isMenuCollapsed,
  isMobileView,
  isMenuUnfixed,
  isMenuShadow,
  leftMenuWidth,
  menuColor,
  role,
  permissions,
}) => {
  const [selectedKeys, setSelectedKeys] = useState(
    store.get('app.menu.selectedKeys') || [],
  );
  const [openedKeys, setOpenedKeys] = useState(
    store.get('app.menu.openedKeys') || [],
  );

  useEffect(() => {
    applySelectedKeys();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, menuData]);

  const applySelectedKeys = () => {
    const flattenItems = (items, key) => items.reduce((flattenedItems, item) => {
      flattenedItems.push(item);
      if (Array.isArray(item[key])) {
        return flattenedItems.concat(flattenItems(item[key], key));
      }
      return flattenedItems;
    }, []);
    const selectedItem = find(flattenItems(menuData, 'children'), [
      'url',
      pathname,
    ]);
    setSelectedKeys(selectedItem ? [selectedItem.key] : []);
  };

  const onCollapse = (value, type) => {
    if (type === 'responsive' && isMenuCollapsed) {
      return;
    }
    dispatch({
      type: 'settings/CHANGE_SETTING',
      payload: {
        setting: 'isMenuCollapsed',
        value: !isMenuCollapsed,
      },
    });
    setOpenedKeys([]);
  };

  const onOpenChange = (keys) => {
    store.set('app.menu.openedKeys', keys);
    setOpenedKeys(keys);
  };

  const handleClick = (e) => {
    store.set('app.menu.selectedKeys', [e.key]);
    setSelectedKeys([e.key]);
  };

  const generateMenuItems = () => {
    const generateItem = (item) => {
      const {
        key, title, url, icon, disabled, count,
      } = item;
      if (item.category) {
        return <Menu.ItemGroup key={Math.random()} title={item.title} />;
      }
      if (item.url) {
        return (
          <Menu.Item key={key} disabled={disabled}>
            {item.target && (
              <a href={url} target={item.target} rel="noopener noreferrer">
                <span className={style.title}>{title}</span>
                {icon}
              </a>
            )}
            {!item.target && (
              <Link to={url}>
                <span className={style.title}>{title}</span>
                {count && (
                  <span className="badge badge-success ml-2">{count}</span>
                )}
                {icon && (
                  <div className={`${style.icon} icon-collapsed-hidden`}>
                    {icon}
                  </div>
                )}
              </Link>
            )}
          </Menu.Item>
        );
      }
      return (
        <Menu.Item key={key} disabled={disabled}>
          <span className={style.title}>{title}</span>
          {count && <span className="badge badge-success ml-2">{count}</span>}
          {icon && (
            <div
              className={`${style.icon} icon-collapsed-hidden`}
              src={icon}
              alt={title}
            >
              {icon}
            </div>
          )}
        </Menu.Item>
      );
    };

    const generateSubmenu = items => items.map((menuItem) => {
      if (menuItem.children) {
        const subMenuTitle = (
          <span key={menuItem.key}>
            <span className={style.title}>{menuItem.title}</span>
            {menuItem.count && (
              <span className="badge badge-success ml-2">
                {menuItem.count}
              </span>
            )}
            {menuItem.icon && (
              <img
                className={`${style.icon} icon-collapsed-hidden`}
                src={menuItem.icon}
                alt={menuItem.title}
              />
            )}
          </span>
        );
        return (
          <Menu.SubMenu title={subMenuTitle} key={menuItem.key}>
            {generateSubmenu(menuItem.children)}
          </Menu.SubMenu>
        );
      }
      return generateItem(menuItem);
    });

    return menuData.map((menuItem) => {
      if (
        menuItem?.analista
          && role === 'analista'
          && (
            !permissions
              || (
                Array.isArray(permissions)
                  && !permissions?.includes(menuItem.key)
              )
          )
      ) {
        return null;
      }
      if (menuItem.roles && !menuItem.roles.includes(role)) {
        return null;
      }
      if (menuItem.children) {
        const subMenuTitle = (
          <span key={menuItem.key}>
            <span className={style.title}>{menuItem.title}</span>
            {menuItem.count && (
              <span className="badge badge-success ml-2">{menuItem.count}</span>
            )}
            {menuItem.icon && (
              <img
                className={`${style.icon} icon-collapsed-hidden`}
                src={menuItem.icon}
                alt={menuItem.title}
              />
            )}
          </span>
        );
        return (
          <Menu.SubMenu title={subMenuTitle} key={menuItem.key}>
            {generateSubmenu(menuItem.children)}
          </Menu.SubMenu>
        );
      }
      return generateItem(menuItem);
    });
  };

  const menuSettings = isMobileView
    ? {
      width: leftMenuWidth,
      collapsible: false,
      collapsed: false,
      onCollapse,
    }
    : {
      width: leftMenuWidth,
      collapsible: true,
      collapsed: isMenuCollapsed,
      onCollapse,
      breakpoint: 'lg',
    };

  return (
    <Layout.Sider
      {...menuSettings}
      className={classNames(`${style.menu}`, {
        [style.white]: menuColor === 'white',
        [style.gray]: menuColor === 'gray',
        [style.dark]: menuColor === 'dark',
        [style.unfixed]: isMenuUnfixed,
        [style.shadow]: isMenuShadow,
      })}
    >
      <div
        className={style.menuOuter}
        style={{
          width: isMenuCollapsed && !isMobileView ? 80 : leftMenuWidth,
          height:
            isMobileView || isMenuUnfixed
              ? 'calc(100% - 64px)'
              : 'calc(100% - 110px)',
        }}
      >
        <div className={style.logoContainer}>
          <div className={style.logo}>
            <img
              src="resources/images/logo.svg"
              style={{ maxHeight: '30px' }}
              className="mr-2"
              alt="GasGas Analytics"
            />
            <div className={style.name}>| GasGas Analytics</div>
          </div>
        </div>
        <PerfectScrollbar>
          <Menu
            onClick={handleClick}
            selectedKeys={selectedKeys}
            openKeys={openedKeys}
            onOpenChange={onOpenChange}
            mode="inline"
            className={style.navigation}
            inlineIndent="15"
          >
            {generateMenuItems()}
          </Menu>
          {!isMenuCollapsed && (
            <Row justify="center" align="bottom">
              <div
                style={{
                  backgroundColor: '#22273b',
                  borderRadius: '5px',
                  padding: '15px 15px',
                  marginTop: '50px',
                  marginBottom: '50px',
                  textAlign: 'center',
                }}
              >
                <p style={{ color: '#868eb7' }}>¿Necesitas más información?</p>
                <Button
                  shape="round"
                  style={{ backgroundColor: '#1ad697', color: '#ffffff' }}
                >
                  Programar llamada
                </Button>
              </div>
              <p style={{ color: '#868eb7' }}>- Powered by GasGas App -</p>
            </Row>
          )}
        </PerfectScrollbar>
      </div>
    </Layout.Sider>
  );
};

export default withRouter(connect(mapStateToProps)(MenuLeft));
