import React from 'react';
import {
  AlertFilled,
  SettingOutlined,
  FilePdfFilled,
  CreditCardFilled,
  QuestionCircleFilled,
  UserSwitchOutlined,
  TeamOutlined,
} from '@ant-design/icons';

export const permissions = [
  {
    category: true,
    key: 'dashboards',
    title: 'Dashboards',
  },
  {
    title: 'Alertas GasGas',
    key: 'home',
    icon: <AlertFilled />,
    url: '/dashboard',
    analista: true,
  },
  {
    title: 'Configuración',
    key: 'config',
    icon: <SettingOutlined />,
    url: '/configuration',
    analista: true,
  },
  {
    title: 'Reportes',
    key: 'report',
    icon: <FilePdfFilled />,
    url: '/reports',
    analista: true,
  },
  {
    title: 'Facturación',
    key: 'billing',
    icon: <CreditCardFilled />,
    url: '/billing/invoice',
    analista: true,
  },
  {
    title: 'Contácto y Ayuda',
    key: 'contact',
    icon: <QuestionCircleFilled />,
    url: '/contact',
  },
  {
    title: 'Administrador',
    key: 'admin',
    icon: <UserSwitchOutlined />,
    url: '/admin',
    roles: ['administrador'],
  },
  {
    title: 'Usuarios Admin',
    key: 'users',
    icon: <TeamOutlined />,
    url: '/users-admin',
    roles: ['administrador'],
  },
];

export default async function getMenuData() {
  return permissions;
}
