import React, { useState } from 'react';
import { connect } from 'react-redux';
import { UserOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import {
  Menu, Dropdown, Avatar, Badge,
} from 'antd';
import styles from './style.module.scss';

const mapStateToProps = ({ user }) => ({ user });

const ProfileMenu = ({ dispatch, user, FormattedMessage }) => {
  const history = useHistory();
  const [count, setCount] = useState(0);

  const logout = (e) => {
    e.preventDefault();
    dispatch({
      type: 'user/LOGOUT',
    });
  };

  const addCount = () => {
    setCount(count);
  };

  const menu = (
    <Menu selectable={false}>
      <Menu.Item key="1">
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault();
            history.push('/me');
          }}
        >
          <i className="fe fe-user mr-2" />
          Editar perfil
        </a>
      </Menu.Item>
      <Menu.Item key="2">
        <a href="#" onClick={logout}>
          <i className="fe fe-log-out mr-2" />
          Cerrar sesión
        </a>
      </Menu.Item>
    </Menu>
  );
  return (
    <Dropdown overlay={menu} trigger={['click']} onVisibleChange={addCount}>
      <div className={styles.dropdown}>
        <Badge count={count}>
          <Avatar
            className={styles.avatar}
            shape="square"
            size="large"
            icon={<UserOutlined />}
          />
        </Badge>
      </div>
    </Dropdown>
  );
};

export default connect(mapStateToProps)(ProfileMenu);
