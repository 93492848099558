import { history } from 'index';
import { notification } from 'antd';
import { all, takeEvery, put } from 'redux-saga/effects';
import actions from '../actions';
import Crud from '../../services/fetch';
import { tokenNameStorage } from '../../helpers';

const publicUrl = new Crud('public', false);
const userUrl = new Crud('user');

export function* LOGIN({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  });
  const info = yield publicUrl.PostUrl('user/login', payload);
  const { data } = info;
  if (data) {
    window.localStorage.setItem(tokenNameStorage, data?.token);
    yield put({
      type: 'user/LOAD_CURRENT_ACCOUNT',
      callback: async () => {
        notification.success({
          message: 'Sesión iniciada',
          description: 'Bienvenido a GasGas Analytics',
        });
      },
    });
  }
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
    },
  });
}

export function* LOGIN_LANDIGPAGE({ token }) {
  if (token) {
    window.localStorage.setItem(tokenNameStorage, token);
    yield put({
      type: 'user/LOAD_CURRENT_ACCOUNT',
      callback: async () => {
        notification.success({
          message: 'Sesión iniciada',
          description: 'Bienvenido a GasGas Analytics',
        });
      },
    });
  }
}

export function* LOAD_CURRENT_ACCOUNT({ callback }) {
  if (window.localStorage.getItem(tokenNameStorage)) {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: true,
      },
    });
    const info = yield userUrl.Get('me');
    if (info) {
      const { data } = info;
      if (data) {
        yield put({
          type: 'user/SET_STATE',
          payload: { ...data, authorized: true },
        });
        if (callback) callback();
        return;
      }
    }
    yield put({
      type: 'user/LOGOUT',
    });
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    });
  }
}

export function* LOGOUT() {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      authorized: false,
      loading: false,
    },
  });
  window.localStorage.clear();
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOGIN, LOGIN),
    takeEvery(actions.LOGIN_LANDIGPAGE, LOGIN_LANDIGPAGE),
    takeEvery(actions.LOAD_CURRENT_ACCOUNT, LOAD_CURRENT_ACCOUNT),
    takeEvery(actions.LOGOUT, LOGOUT),
    LOAD_CURRENT_ACCOUNT({}), // run once on app load to check user auth
  ]);
}
